import React, {
    PropsWithChildren,
    useEffect,
    useState,
    useCallback,
} from "react";
import { useContext } from "react";
import _ from "lodash";
import StyledToasts from "./ToastContext.styles";
import Text from "../../components/Text/Text";
import { Toasts } from "./ToastContext.types";
import { TextVariantEnum } from "../../components/Text/Text.types";
import { ToastStatus } from "./Toast/Toast.types";
import Toast from "./Toast/Toast";

export const defaultToasts: Toasts = {
    items: [],
};

const ToastContext = React.createContext({
    addToast: async ({
        title,
        description,
        status,
    }: {
        title: string;
        description: string;
        status: ToastStatus;
    }) => {},
});

export const ToastContextProvider: React.FunctionComponent<
    PropsWithChildren<{}>
> = ({ children }) => {
    const [currentToasts, setCurrentToasts] = useState<Toasts>(defaultToasts);

    const addToast = async ({
        title,
        description,
        status,
    }: {
        title: string;
        description: string;
        status: ToastStatus;
    }) => {
        setCurrentToasts((state) => {
            let updatedState = _.cloneDeep(state);
            const timeCreated = new Date();
            updatedState.items?.push({
                title,
                description,
                status,
                timeCreated: timeCreated.getTime(),
                readyToDispose: false,
            });
            return updatedState;
        });
    };

    const deleteToast = useCallback(
        (timeCreated: number) => {
            const toastListItem = currentToasts?.items?.filter(
                (e) => e.timeCreated !== timeCreated
            );
            setCurrentToasts({ items: toastListItem });
        },
        [currentToasts, setCurrentToasts]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentToasts?.items?.length) {
                deleteToast(currentToasts?.items[0].timeCreated);
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [currentToasts, deleteToast]);

    return (
        <ToastContext.Provider
            value={{
                addToast,
            }}
        >
            <StyledToasts>
                {currentToasts.items?.map((toast) => (
                    <Toast
                        key={toast.timeCreated}
                        {...toast}
                        deleteToast={deleteToast}
                    />
                ))}
            </StyledToasts>
            {children}
        </ToastContext.Provider>
    );
};

export const ToastContextConsumer = ToastContext.Consumer;
export default ToastContext;

export const useToastContext = () => {
    const context = useContext(ToastContext);
    return context;
};
