import React from "react";
import { BasketContextProvider } from "./BasketContext/BasketContext";
import { TooltipContextProvider } from "./TooltipContext/TooltipContext";
import { ToastContextProvider } from "./ToastContext/ToastContext";
import { SearchContextProvider } from "./SearchContext/SearchContext";
import { combineComponents } from "./combineComponents";

const providers = [
    ToastContextProvider,
    SearchContextProvider,
    BasketContextProvider,
    TooltipContextProvider,
];
export const AppContextProvider = combineComponents(...providers);
