import React, { PropsWithChildren } from "react";
import { IText } from "./Text.types";
import StyledText from "./Text.styles";

const Text = ({ variant, as, children }: PropsWithChildren<IText>) => {
    return (
        <StyledText variant={variant} as={as}>
            {children}
        </StyledText>
    );
};

export default Text;
