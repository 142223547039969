import React, { useEffect } from "react";
import StyledToast from "./Toast.styles";
import Text from "../../../components/Text/Text";
import { IToast } from "./Toast.types";
import { TextVariantEnum } from "../../../components/Text/Text.types";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/Button.types";
import CancelIcon from "../../../assets/vector/cancel.svg";

const Toast = ({
    status,
    timeCreated,
    title,
    description,
    deleteToast,
}: IToast) => {
    const { sectionTitle, primary } = TextVariantEnum;
    const { iconButton } = ButtonVariantEnum;

    return (
        <StyledToast status={status}>
            <div className="toast__close-button-container">
                <Button
                    iconHeight="32px"
                    iconWidth="32px"
                    LeftIcon={CancelIcon}
                    variant={iconButton}
                    onClick={() => deleteToast(timeCreated)}
                />
            </div>
            <Text variant={sectionTitle}>{title}</Text>
            <Text variant={primary}>{description}</Text>
        </StyledToast>
    );
};

export default Toast;
