import React, { PropsWithChildren, useEffect, useState } from "react";
import { useContext, useCallback } from "react";
import _ from "lodash";
import {
    OakProductCategoryEnum,
    OakProductCategory,
} from "../../types/dto/product";
import { OakProductDTO } from "../../types/dto/product";
import { useStaticQuery, graphql } from "gatsby";
import { AutocompleteOption } from "../../components/TextInput/TextInput.types";

interface ISearchContext {
    category: OakProductCategory;
    query: string;
    itemsPerPage: number;
    productsQuantity: number;
    pagesQuantity: number;
    currentPage: number;
    hasNext: boolean;
    hasPrevious: boolean;
    autocompleteOptions: AutocompleteOption[];
    allOakProducts: { node: OakProductDTO }[];
    oakProducts?: { node: OakProductDTO }[];
    paginatedOakProducts?: { node: OakProductDTO }[][];
}

const defaultSearchContext: ISearchContext = {
    category: OakProductCategoryEnum.all,
    query: "",
    itemsPerPage: 12,
    productsQuantity: 0,
    pagesQuantity: 1,
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
};

const SearchContext = React.createContext({
    searchContext: defaultSearchContext,
    setQuery: (query: string) => {},
    setCategory: ({ value }: { value: OakProductCategory }) => {},
    getPage: (pageNumber: number) => {},
    autocompleteOptions: [],
});

export const SearchContextProvider: React.FunctionComponent<
    PropsWithChildren<{}>
> = ({ children }) => {
    const productCards = useStaticQuery(
        graphql`
            query productCardsQuery {
                allContentfulOakProduct {
                    edges {
                        node {
                            id
                            category
                            images {
                                gatsbyImageData
                            }
                            name
                        }
                    }
                }
            }
        `
    );

    const category = OakProductCategoryEnum.all;
    const itemsPerPage = 12;
    const currentPage = 1;
    const productsQuantity =
        productCards?.allContentfulOakProduct?.edges?.length;
    const pagesQuantity = Math.ceil(productsQuantity / itemsPerPage);
    const hasNext = productsQuantity / itemsPerPage > 1 ? true : false;
    const hasPrevious = currentPage > 1 ? true : false;
    const allOakProducts = productCards?.allContentfulOakProduct?.edges;

    const autocompleteOptions = allOakProducts.map((el) => {
        return {
            value: el?.node?.name,
            label: el?.node?.name,
        };
    });

    const defaultSearchContext: ISearchContext = {
        category,
        query: "",
        itemsPerPage,
        productsQuantity,
        pagesQuantity,
        currentPage: 1,
        hasNext,
        hasPrevious,
        allOakProducts,
        oakProducts: productCards?.allContentfulOakProduct?.edges,
        paginatedOakProducts: _.chunk(
            productCards?.allContentfulOakProduct?.edges,
            itemsPerPage
        ),
        autocompleteOptions,
    };
    _.cloneDeep;
    const [searchContext, setSearchContext] =
        useState<ISearchContext>(defaultSearchContext);

    const setQuery = useCallback(
        _.debounce((query: string) => {
            setSearchContext((state) => {
                let updatedState = _.cloneDeep(state);
                updatedState.query = query;
                return updatedState;
            });
        }, 100),
        []
    );

    const setCategory = ({ value }: { value: OakProductCategory }) => {
        setSearchContext((state) => {
            let updatedState = _.cloneDeep(state);
            updatedState.category = value;
            return updatedState;
        });
    };

    const getPage = (pageNumber: number) => {
        setSearchContext((state) => {
            let updatedState = _.cloneDeep(state);
            updatedState.currentPage = pageNumber;
            updatedState.oakProducts = updatedState?.paginatedOakProducts
                ? updatedState?.paginatedOakProducts[
                      updatedState.currentPage - 1
                  ]
                : [];
            updatedState.hasPrevious =
                updatedState.currentPage > 1 ? true : false;

            updatedState.hasNext =
                updatedState?.paginatedOakProducts &&
                updatedState?.paginatedOakProducts?.length >
                    updatedState.currentPage
                    ? true
                    : false;
            return updatedState;
        });
    };

    const executeSearch = () => {
        setSearchContext((state) => {
            let updatedState = _.cloneDeep(state);

            updatedState.oakProducts =
                productCards?.allContentfulOakProduct?.edges?.filter(
                    (product) =>
                        product.node.name
                            .toLowerCase()
                            .includes(
                                updatedState.query
                                    ? updatedState.query?.toLowerCase()
                                    : ""
                            )
                );

            updatedState.oakProducts =
                updatedState.category.toLowerCase() !==
                OakProductCategoryEnum.all.toLowerCase()
                    ? updatedState?.oakProducts?.filter((product) => {
                          return (
                              product.node.category.toLowerCase() ===
                              updatedState.category.toLowerCase()
                          );
                      })
                    : updatedState.oakProducts;

            updatedState.productsQuantity =
                updatedState?.oakProducts?.length || 0;

            updatedState.pagesQuantity = Math.ceil(
                updatedState.productsQuantity / updatedState.itemsPerPage
            );

            updatedState.paginatedOakProducts = _.chunk(
                updatedState.oakProducts,
                itemsPerPage
            );

            updatedState.oakProducts = updatedState.paginatedOakProducts[0];

            updatedState.currentPage = 1;

            updatedState.hasPrevious =
                updatedState.currentPage > 1 ? true : false;

            updatedState.hasNext =
                updatedState?.paginatedOakProducts?.length >
                updatedState.currentPage
                    ? true
                    : false;

            return updatedState;
        });
    };

    useEffect(() => {
        executeSearch();
    }, [searchContext?.category, searchContext?.query]);

    return (
        <SearchContext.Provider
            value={{
                searchContext,
                setQuery,
                setCategory,
                getPage,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const SearchContextConsumer = SearchContext.Consumer;
export default SearchContext;

export const useSearchContext = () => {
    const context = useContext(SearchContext);
    return context;
};
