import styled, { css, keyframes } from "styled-components";
import { IStyledToast, ToastStatus } from "./Toast.types";
import { ColorsEnum } from "../../../types/global/colors";

const statusStyles = (status: ToastStatus = ToastStatus.success) =>
    ({
        success: css`
            border: 1px solid ${ColorsEnum.green_05};
            background-color: ${ColorsEnum.green_09};
        `,
        warning: css`
            border: 1px solid ${ColorsEnum.yellow_05};
            background-color: ${ColorsEnum.yellow_09};
        `,
        error: css`
            border: 1px solid ${ColorsEnum.red_05};
            background-color: ${ColorsEnum.red_09};
        `,
        info: css`
            border: 1px solid ${ColorsEnum.sky_05};
            background-color: ${ColorsEnum.sky_09};
        `,
    }[status]);

const toastInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const StyledToast = styled.div<IStyledToast>`
    position: relative;
    margin-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: 0.9;
    transition: 0.3s ease;

    width: 365px;
    padding: 0px 15px 0px 10px;

    bottom: 1rem;
    right: 1rem;
    animation: ${toastInRight} 0.4s;

    .toast__close-button-container {
        position: absolute;
        right: 0;
        top: 13px;
    }

    &:hover {
        box-shadow: 0 0 12px #fff;
        opacity: 1;
    }

    ${({ status }) => statusStyles(status)}
`;

export default StyledToast;
