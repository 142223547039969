import styled, { css, keyframes } from "styled-components";
import { IStyledTooltip } from "./TooltipContext.types";

const fadeInTooltip = keyframes`
    0% {
        opacity: 0 ;
    }
    30% {
        opacity: 0 ;
    }
    100% {
        opacity: 1;
    }
`;

const StyledTooltip = styled.div<IStyledTooltip>`
  position: fixed;
  z-index: 60;
  top: 0;
  height: 0;
  width: 0;

  ${({ isVisible }) =>
    isVisible === false &&
    css`
      display: none;
    `}

  .tooltip__ {
    &tooltip-container {
      width: fit-content;
      opacity: 0;
      position: absolute;
      padding: 20px;
      background-color: white;
      box-shadow: 0 0 10px #999;
      left: ${({ offsetLeft }) => offsetLeft + "px"};
      top: ${({ offsetTop }) => offsetTop + "px"};
      animation: ${fadeInTooltip} 0.2s ease-in;
      animation-fill-mode: forwards;
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
`;

export default StyledTooltip;
