import { FontSize, FontWeight, TagType } from "../../types/global/fonts";
import { Colors } from "../../types/global/colors";

interface IText {
  variant?: TextVariant;
  as?: TagType;
}

enum TextVariantEnum {
  logoHeader = "logoHeader",
  footerHeader = "footerHeader",
  sectionTitle = "sectionTitle",
  primary = "primary",
  primaryWhite = "primaryWhite",
  primaryTitle = "primaryTitle",
  tinyWhite = "tinyWhite",
  landingTitle = "landingTitle",
  landingDescription = "landingDescription",
}

type TextVariant =
  | TextVariantEnum.logoHeader
  | TextVariantEnum.footerHeader
  | TextVariantEnum.sectionTitle
  | TextVariantEnum.primary
  | TextVariantEnum.primaryWhite
  | TextVariantEnum.primaryTitle
  | TextVariantEnum.tinyWhite
  | TextVariantEnum.landingTitle
  | TextVariantEnum.landingDescription;

interface IStyledText {
  variant?: TextVariant;
  size?: FontSize;
  color?: Colors;
  weight?: FontWeight;
  as?: TagType;
}

export type { IText, TextVariant, IStyledText };

export { TextVariantEnum };
