import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import { useContext } from "react";
import _ from "lodash";
import StyledTooltip from "./TooltipContext.styles";
import { nullHandler } from "../../utils/utils";
import { useMousePosition } from "../../utils/hooks/useMousePosition";
import { ITooltipPosition } from "./TooltipContext.types";
import useOutsideClickAlerter from "../../utils/hooks/useOutsideClickAlerter";
import useMouseLeaveAlerter from "../../utils/hooks/useMouseLeaveAlerter";
import useHover from "../../utils/hooks/useHover";

const TooltipContext = React.createContext({
  showTooltip: async ({ Content }: { Content: any }) => {},
  hideTooltip: async () => {},
});

export const TooltipContextProvider: React.FunctionComponent<
  PropsWithChildren<{}>
> = ({ children }) => {
  const [CurrentTooltipContent, setCurrentTooltipContent] = useState<any>(null);
  const [tooltipPosition, setTooltipPosition] = useState<ITooltipPosition>({
    x: 0,
    y: 0,
  });

  const tooltipRef = useRef<HTMLDivElement>(null);
  const { x, y } = useMousePosition();

  const tooltipCursorOffset = 40;

  const showTooltip = async ({ Content }: { Content: any }) => {
    setCurrentTooltipContent(Content);
  };

  const hideTooltip = async () => {
    setCurrentTooltipContent(null);
    setTooltipPosition({
      x: 0,
      y: 0,
    });
  };

  useOutsideClickAlerter(tooltipRef, hideTooltip);

  const [] = useHover(tooltipRef, () => {}, hideTooltip);

  useEffect(() => {
    setTooltipPosition({
      x: x + tooltipCursorOffset - (tooltipRef?.current?.offsetWidth || 0),
      y: y - tooltipCursorOffset,
    });
  }, [tooltipRef?.current?.offsetWidth]);

  return (
    <TooltipContext.Provider
      value={{
        showTooltip,
        hideTooltip,
      }}
    >
      <StyledTooltip
        isVisible={nullHandler(CurrentTooltipContent) ? true : false}
        offsetLeft={tooltipPosition.x}
        offsetTop={tooltipPosition.y}
      >
        <div className="tooltip__tooltip-container" ref={tooltipRef}>
          {CurrentTooltipContent}
        </div>
      </StyledTooltip>
      {children}
    </TooltipContext.Provider>
  );
};

export const TooltipContextConsumer = TooltipContext.Consumer;
export default TooltipContext;

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  return context;
};
