enum ToastStatus {
    success = "success",
    warning = "warning",
    error = "error",
    info = "info",
}

interface IToast {
    title: string;
    description: string;
    status: ToastStatus;
    timeCreated: number;
    readyToDispose: boolean;
    deleteToast: Function;
}

interface IStyledToast {
    status: ToastStatus;
}

export type { IStyledToast, IToast };
export { ToastStatus };
