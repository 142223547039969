import React from "react";
import GlobalStyles from "./globalStyles";
import "./src/assets/fonts/fonts.css";
import { AppContextProvider } from "./src/context/AppContextProvider";

export const wrapRootElement = ({ element }) => {
    return (
        <AppContextProvider>
            <GlobalStyles />
            {element}
        </AppContextProvider>
    );
};
