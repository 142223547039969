import styled from "styled-components";

const StyledToasts = styled.div`
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
`;

export default StyledToasts;
