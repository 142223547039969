import styled, { css } from "styled-components";
import { IStyledText } from "./Text.types";
import { TextVariant, TextVariantEnum } from "./Text.types";
import { FontSizeEnum, FontWeightEnum } from "../../types/global/fonts";
import { ColorsEnum } from "../../types/global/colors";
import BreakPointsEnum from "../../types/global/global";

const variantStyles = (variant: TextVariant = TextVariantEnum.primary) =>
  ({
    logoHeader: css`
      font-size: ${FontSizeEnum._32px};
      font-weight: ${FontWeightEnum._900};
    `,
    footerHeader: css`
      font-size: ${FontSizeEnum._40px};
      font-weight: ${FontWeightEnum._900};
      color: ${ColorsEnum.gray_10};
      margin-top: 20px;
      margin-bottom: 20px;
    `,
    sectionTitle: css`
      font-size: ${FontSizeEnum._24px};
      font-weight: ${FontWeightEnum._700};
    `,
    primary: css`
      font-size: ${FontSizeEnum._16px};
      font-weight: ${FontWeightEnum._400};

      @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        font-size: ${FontSizeEnum._14px};
      }
    `,
    primaryWhite: css`
      font-size: ${FontSizeEnum._16px};
      font-weight: ${FontWeightEnum._400};
      color: ${ColorsEnum.gray_10};
    `,
    primaryTitle: css`
      font-size: ${FontSizeEnum._16px};
      font-weight: ${FontWeightEnum._700};

      @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        font-size: ${FontSizeEnum._14px};
      }
    `,
    tinyWhite: css`
      font-size: ${FontSizeEnum._8px};
      font-weight: ${FontWeightEnum._400};
      color: ${ColorsEnum.gray_10};
    `,
    landingTitle: css`
      font-size: ${FontSizeEnum._80px};
      font-weight: ${FontWeightEnum._900};
      color: ${ColorsEnum.gray_10};
      @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        font-size: ${FontSizeEnum._40px};
      }
    `,
    landingDescription: css`
      font-size: ${FontSizeEnum._40px};
      font-weight: ${FontWeightEnum._400};
      color: ${ColorsEnum.gray_10};
      @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        font-size: ${FontSizeEnum._24px};
      }
    `,
  }[variant]);

const StyledText = styled.p<IStyledText>`
  ${({ variant }) => variantStyles(variant)};
`;

export default StyledText;
