import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
    Dispatch,
} from "react";
import { useContext } from "react";
import { useSearchContext } from "../SearchContext/SearchContext";
import _ from "lodash";
import { useToastContext } from "../ToastContext/ToastContext";
import { ToastStatus } from "../ToastContext/Toast/Toast.types";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

enum DeliveryMethod {
    pickupInTheOffice = "pickupInTheOffice",
    courier = "courier",
    parcelLocker = "parcelLocker",
}

interface OakProductOrderData {
    id: string;
    name: string;
    quantity: number;
    size: string;
    image: any;
}

interface Basket {
    items?: OakProductOrderData[];
}

interface Order {
    basket: Basket;
    email: string;
    deliveryMethod: DeliveryMethod;
    street?: string;
    buildingNo?: string;
    premisesNo?: string;
    city?: string;
    zipCode?: string;
    parcelLockerCityStreetOrZipCode?: string;
}

export const defaultBasket: Basket = {
    items: [],
};

const BasketContext = React.createContext({
    basket: defaultBasket,
    setCurrentBasket: Dispatch<React.SetStateAction<Basket>>,
    addItem: async (oakProductId: string, selectedSize: string | null) => {},
    increaseQuantity: async (
        oakProductId: string,
        selectedSize: string | null
    ) => {},
    decreaseQuantity: async (
        oakProductId: string,
        selectedSize: string | null
    ) => {},
    removeItem: async (oakProductId: string, selectedSize: string | null) => {},
});

const OAK_PRODUCTS_BASKET = "oak-products-basket";

export const BasketContextProvider: React.FunctionComponent<
    PropsWithChildren<{}>
> = ({ children }) => {
    const [currentBasket, setCurrentBasket] = useState<Basket>(defaultBasket);

    const [storedCurrentBasket, setStoredCurrentBasket] = useLocalStorage(
        OAK_PRODUCTS_BASKET,
        currentBasket
    );

    const { addToast } = useToastContext();

    const {
        searchContext: { allOakProducts },
    } = useSearchContext();

    const addItem = async (
        oakProductId: string,
        selectedSize: string | null
    ) => {
        const product = allOakProducts?.find(
            (product) => product.node.id === oakProductId
        );

        if (
            currentBasket?.items?.some(
                (el) => el.id === oakProductId && el.size === selectedSize
            )
        ) {
            if (product) {
                setCurrentBasket((state) => {
                    let updatedState = _.cloneDeep(state);

                    let arrayId = updatedState.items?.findIndex(
                        (item) =>
                            item.id === oakProductId &&
                            item.size === selectedSize
                    );

                    if (typeof arrayId === "number") {
                        if (arrayId !== -1) {
                            if (updatedState.items) {
                                updatedState.items[arrayId].quantity += 1;

                                return updatedState;
                            }
                        }
                    } else {
                        updatedState?.items?.push({
                            id: product?.node?.id,
                            name: product?.node?.name,
                            quantity: 1,
                            size: selectedSize,
                            image: product?.node?.images[0],
                        });

                        return updatedState;
                    }
                });
            }

            addToast({
                title: "Great!",
                description: `Another ${product?.node.name} has been added to your basket.`,
                status: ToastStatus.success,
            });
        } else {
            if (product) {
                setCurrentBasket((state) => {
                    let updatedState = _.cloneDeep(state);
                    updatedState?.items?.push({
                        id: product?.node?.id,
                        name: product?.node?.name,
                        quantity: 1,
                        size: selectedSize,
                        image: product?.node?.images[0],
                    });

                    return updatedState;
                });
            }

            addToast({
                title: "Great!",
                description: `${product?.node.name} has been added to your basket.`,
                status: ToastStatus.success,
            });
        }
    };

    const decreaseQuantity = async (
        oakProductId: string,
        selectedSize: string | null
    ) => {
        if (
            currentBasket?.items?.some(
                (el) => el.id === oakProductId && el.size === selectedSize
            )
        ) {
            setCurrentBasket((state) => {
                let updatedState = _.cloneDeep(state);

                let arrayId = updatedState.items?.findIndex(
                    (item) =>
                        item.id === oakProductId && item.size === selectedSize
                );

                if (typeof arrayId === "number") {
                    if (arrayId !== -1 && updatedState.items) {
                        if (updatedState.items[arrayId].quantity > 1) {
                            updatedState.items[arrayId].quantity -= 1;

                            return updatedState;
                        } else {
                            removeItem(oakProductId, selectedSize);
                        }
                    }
                }
            });
        }
    };

    const increaseQuantity = async (oakProductId: string) => {
        setCurrentBasket((state) => {
            let updatedState = _.cloneDeep(state);
            updatedState?.items?.map((item) => {
                if (item.id === oakProductId) {
                    item.quantity += 1;
                }
            });

            return updatedState;
        });
    };

    useEffect(() => {
        setStoredCurrentBasket(currentBasket);
    }, [currentBasket]);

    const removeItem = async (
        oakProductId: string,
        selectedSize: string | null
    ) => {
        if (
            currentBasket?.items?.some(
                (el) => el.id === oakProductId && el.size === selectedSize
            )
        ) {
            setCurrentBasket((state) => {
                let updatedState = _.cloneDeep(state);
                if (selectedSize !== null) {
                    updatedState.items = updatedState?.items?.filter(
                        (item) => item.size !== selectedSize
                    );
                } else {
                    updatedState.items = updatedState?.items?.filter(
                        (item) => item.id !== oakProductId
                    );
                }

                return updatedState;
            });
        }
    };

    return (
        <BasketContext.Provider
            value={{
                basket: currentBasket,
                setCurrentBasket,
                addItem,
                increaseQuantity,
                decreaseQuantity,
                removeItem,
            }}
        >
            {children}
        </BasketContext.Provider>
    );
};

export const BasketContextConsumer = BasketContext.Consumer;
export default BasketContext;

export const useBasketContext = () => {
    const context = useContext(BasketContext);
    return context;
};

export { DeliveryMethod, OAK_PRODUCTS_BASKET };
export type { OakProductOrderData, Basket };
